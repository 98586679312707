<template>
  <div>
    <AddExercise
      ref="addExerciseRef"
      use-for="toExercises"
      :isExerciseBank="1"
      :exercise-id="exerciseId"
      :is-edit="isEdit"
      :res-req-params-map="resReqParamsMap"
      @back-click="handleBack"
      @save-click="handleSave"
    />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import AddExercise from '@/components/AddExercise/AddExercise.vue';
  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { createExercise, getExerciseDetail, editExercise } from '@/api/exercises.js';

  const { SUCCESS } = resStatusEnum;
  const route = useRoute();
  const router = useRouter();
  const message = useMessage();
  const loading = ref(false);

  const exerciseId = route.query.id;
  const isEdit = !!exerciseId;

  const resReqParamsMap = isEdit ? {
    id: 'Exercises[id]', // String
    title: 'Exercises[title]', // String
    type: 'Exercises[type]', // String
    ques_select: 'Exercises[ques_select]', // Array
    ques_select_num: 'Exercises[ques_select_num]', // Number
    ques_answer: 'Exercises[ques_answer]', // String
    ques_analys: 'Exercises[ques_analys]', // String
    score: 'Exercises[score]', // String
    label_ids: 'Exercises[label]', // Array
  } : {
    title: 'Exercises[title]', // String
    type: 'Exercises[type]', // String
    ques_select: 'Exercises[ques_select]', // Array
    ques_select_num: 'Exercises[ques_select_num]', // Number
    ques_answer: 'Exercises[ques_answer]', // String
    ques_analys: 'Exercises[ques_analys]', // String
    score: 'Exercises[score]', // String
    label_ids: 'Exercises[label]', // Array
  };

  const handleBack = () => {
    router.push('list');
  };
  const handleSave = reqData => {
    
    loading.value = true;
    let reqFn;
    let successMsg = '';
    if (isEdit) {
      reqFn = editExercise;
      successMsg = '编辑试题成功';
    } else {
      reqFn = createExercise;
      successMsg = '新增试题成功';
    }
    reqFn(reqData).then(res => {
      if (res.code === SUCCESS) {
        message.success(successMsg);
        setTimeout(() => {
          handleBack();
        }, 120);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  const addExerciseRef = ref(null);
  if (isEdit) {
    loading.value = true;
    getExerciseDetail({
      'Exercises[id]]': exerciseId
    }).then(res => {
      if (res.code === SUCCESS) {
        addExerciseRef.value.initFormValue(res.data);
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }
</script>